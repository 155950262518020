import { CommonState, Fetcher } from '@primeit/components-vue';

const module = {
  actions: {
    FETCH_EMERGENCY_TERM: async (context, params) => {
      let json;
      try {
        const response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_USER}/term?protocol=${params}`);
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      CommonState.commit('credential/POPULATE_TERM_USER', json);
    },
    FETCH_EMERGENCY_TERM_ACCEPT: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_USER}/term`, {
          body: JSON.stringify(params),
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
          },
          method: 'POST',
        });
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }

      return json;
    },
    FETCH_USER_CHILDREN_PSO: async (context, params) => {
      let json;
      try {
        const response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_USER_CREDENTIAL}/pso/child`);
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_USER_CHILDREN_PSO', json);
    },    
    FETCH_USER_PSO: async (context, params) => {
      let json;
      try {
        const response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_USER_CREDENTIAL}/pso/self`);
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_USER_PSO', json);
    },
  },
  getters: {
    USER_CHILDREN_PSO: (state) => {
      return JSON.parse(JSON.stringify(state.user_children_pso));
    },
    USER_PSO: (state) => {
      return JSON.parse(JSON.stringify(state.user_pso));
    },
  },
  mutations: {
    POPULATE_USER_CHILDREN_PSO: (state, payload) => {
      if (payload) {
        state.user_children_pso = payload;
      }
    },
    POPULATE_USER_PSO: (state, payload) => {
      if (payload) {
        state.user_pso = payload;
      }
    },
  },
  namespaced: true,
  state: {
    user_children_pso: null,
    user_pso: null,
  },
  strict: true,  
};

CommonState.registerModule('user', module);
