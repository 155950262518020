import { CommonRouter } from '@primeit/components-vue';

import person from './person';
import professional from './professional';

CommonRouter.registerRoutes([
  {
    children: [
      {
        children: professional,
        component: () => import(/* webpackChunkName: "app.common.emergency" */ './Common.vue'),
        meta: {
          label: 'emergency.attendance_now',
          secure: true,
        },
        path: '',
      },
    ],
    component: () => import(/* webpackChunkName: "app.common" */ '../common/professional/Common.vue'),
    meta: {
      icon: 'local_hospital',
      label: 'emergency.attendance_now',
      labelByRole: {
        D: 'emergency.virtual_line',
        DC: 'emergency.virtual_line',
        P: 'emergency.attendance_now',
      },
      order: 1,
      spotlight: true,
      visible: true,
      visibleRules: [],
    },
    path: '/emergency/professional',
  },
  {
    children: [
      {
        children: person,
        component: () => import(/* webpackChunkName: "app.common.emergency" */ './Common.vue'),
        meta: {
          label: 'emergency.attendance_now',
          secure: true,
        },
        path: '',
      },
    ],
    component: () => import(/* webpackChunkName: "app.common" */ '../common/person/Common.vue'),
    meta: {
      icon: 'local_hospital',
      label: 'emergency.attendance_now',
      labelByRole: {
        D: 'emergency.virtual_line',
        DC: 'emergency.virtual_line',
        P: 'emergency.attendance_now',
      },
      order: 1,
      spotlight: true,
      visible: true,
      visibleRules: [],
    },
    path: '/emergency/person',
  },
]);
