import { CommonRouter } from '@primeit/components-vue';

CommonRouter.registerRoutes([
  {
    children: [
      {
        component: () => import(/* webpackChunkName: "security.eso" */ './Pso.vue'),
        meta: {
          visible: false,
        },
        path: ':id',
      },
      {
        component: () => import(/* webpackChunkName: "security.eso" */ './Pso.vue'),
        meta: {
          visible: false,
        },
        path: ':id/emergency',
      },
      {
        component: () => import(/* webpackChunkName: "security.eso" */ './Pso.vue'),
        meta: {
          visible: false,
        },
        path: ':id/message',
      },
      {
        component: () => import(/* webpackChunkName: "security.eso" */ './Pso.vue'),
        meta: {
          visible: false,
        },
        path: ':id/registerdependent',
      },
    ],
    component: () => import(/* webpackChunkName: "security.common" */ '../common/Dummy.vue'),
    meta: {
      visible: false,
    },
    path: '/pso',
  },
]);
