import { Config } from '@dav/security-component-spa';
import { API, CommonState, Fetcher, FetchHelper } from '@primeit/components-vue';
import moment from 'moment';

const fetchHelper = new FetchHelper();

const module = {
  actions: {
    FETCH_ALL: async (context, params) => {
      params.fields = params.fields ? params.fields : 'id,company_id';
      const responseJson = await fetchHelper.fetchAll(
        `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_APPOINTMENT}/consultorio`,
        params
      );
      const json = {
        appointment: responseJson,
        ts: Date.now(),
      };
      context.commit('POPULATE_ALL', json);
    },

    FETCH_CANCEL_PIX: async (context, params) => {
      const apiName = 'FinancialAPI';
      const path = '/payment';

      const config = await Config.getInstance();

      const myInit = {
        headers: {
          'x-company-id': config.get('dav-company-id'),
          'x-utc-offset': moment().utcOffset().toString(),
        },
        queryStringParameters: {
          access: params.id,
          protocol: params.protocol,
        },
        response: true,
      };

      try {
        const response = await API.patch(apiName, path, myInit);
        context.commit('POPULATE_PAYMENT', response.data);
      } catch (error) {
        if (!error?.response?.data) {
          error.response.data = {
            code: 400,
            message: 'Impossível acessar o servidor. Tente novamente',
          };
        }
        context.commit('common/POPULATE_FEEDBACK', error.response.data, {
          root: true,
        });
      }
    },

    FETCH_CONFIRM_PAYMENT: async (context, params) => {
      const apiName = 'FinancialAPI';
      const path = '/confirmpayment';

      const config = await Config.getInstance();

      const myInit = {
        body: {},
        headers: {
          'x-company-id': config.get('dav-company-id'),
          'x-utc-offset': moment().utcOffset().toString(),
        },
        queryStringParameters: {
          access: params.id,
          protocol: params.protocol,
          refuse: params.refuse,
        },
        response: true,
      };

      const json = await API.post(apiName, path, myInit)
        .then((payload) => {
          if (payload.status === 200) {
            return payload.data;
          } else {
            return false;
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            context.commit('common/POPULATE_FEEDBACK', error.response.data, {
              root: true,
            });
            return error.response.data;
          } else {
            return {
              code: 400,
              message: 'Impossível acessar o servidor. Tente novamente',
            };
          }
        });

      context.commit('POPULATE_CONFIRM_PAYMENT', json);
    },

    FETCH_DELETE: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_APPOINTMENT}/${params}`, {
          method: 'DELETE',
        });
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }

      context.commit('common/POPULATE_FEEDBACK', json, {
        root: true,
      });
    },
    FETCH_DELETE_FILE_LOGGED: async (context, params) => {
      let response;
      let json;
      const filePath = params.filePath;
      try {
        response = await Fetcher(
          `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_APPOINTMENT}/${params.id}/file?filePath=${filePath}`,
          {
            headers: {
              Accept: 'application/json, text/plain, */*',
              'Content-Type': 'application/json',
            },
            method: 'DELETE',
          }
        );
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }

      context.commit('POPULATE_FILE', json);
    },
    FETCH_DETAIL: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(
          `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_APPOINTMENT}/${params.id}/consultorio/detail`
        );
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }

      context.commit('POPULATE_ONE', json);
    },

    FETCH_GET_CANCEL: async (context, params) => {
      const apiName = 'AppointmentAPI';
      const path = '/consultorio/cancel';
      const myInit = {
        headers: {},
        queryStringParameters: {
          access: params,
        },
        response: true,
      };

      const user = CommonState.getters['credential/CURRENT_USER'];
      if (user) {
        myInit.headers['x-api-key'] = user.signInUserSession.accessToken.jwtToken;
      }

      const json = await API.get(apiName, path, myInit)
        .then((payload) => {
          if (payload.status === 200) {
            return payload.data;
          } else {
            return {
              code: 400,
              message: 'Unable to retrieve data',
            };
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            context.commit('common/POPULATE_FEEDBACK', error.response.data, {
              root: true,
            });
            return error.response.data;
          } else {
            return {
              code: 400,
              message: 'Impossível acessar o servidor. Tente novamente',
            };
          }
        });

      context.commit('POPULATE_CANCEL', json);
    },

    FETCH_GET_PAYMENT: async (context, params) => {
      const apiName = 'FinancialAPI';
      const path = '/payment';

      const config = await Config.getInstance();

      const myInit = {
        headers: {
          'x-dav-company-id': config.get('dav-company-id'),
          'x-utc-offset': moment().utcOffset().toString(),
        },
        queryStringParameters: {
          access: params.id,
          protocol: params.protocol,
        },
        response: true,
      };

      const user = CommonState.getters['credential/CURRENT_USER'];

      if (user) {
        myInit.headers['x-api-key'] = user.signInUserSession.accessToken.jwtToken;
        myInit.headers['x-company-id'] = params.company_id;
      }

      const json = await API.get(apiName, path, myInit)
        .then((payload) => {
          if (payload.status === 200) {
            return payload.data;
          } else {
            return false;
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            context.commit('common/POPULATE_FEEDBACK', error.response.data, {
              root: true,
            });
            return error.response.data;
          } else {
            return {
              code: 400,
              message: 'Impossível acessar o servidor. Tente novamente',
            };
          }
        });

      context.commit('POPULATE_PAYMENT', json);
    },

    FETCH_GET_VOUCHERVALID: async (context, params) => {
      const apiName = 'FinancialAPI';
      const path = '/vouchervalid';
      const myInit = {
        headers: {
          'x-utc-offset': moment().utcOffset().toString(),
        },
        queryStringParameters: {
          access: params.access,
          protocol: params.protocol,
          voucher: params.voucher,
        },
        response: true,
      };

      const user = CommonState.getters['credential/CURRENT_USER'];

      if (user) {
        myInit.headers['x-api-key'] = user.signInUserSession.accessToken.jwtToken;
      }
      myInit.headers['x-company-id'] = params.company_id;

      const json = await API.get(apiName, path, myInit)
        .then((payload) => {
          if (payload.status === 200) {
            return payload.data;
          } else {
            return false;
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            context.commit('common/POPULATE_FEEDBACK', error.response.data, {
              root: true,
            });
            return error.response.data;
          } else {
            return {
              code: 400,
              message: 'Impossível acessar o servidor. Tente novamente',
            };
          }
        });

      context.commit('POPULATE_VOUCHERVALID', json);
    },

    FETCH_LIST: async (context, params) => {
      const response = await fetchHelper.fetchAll(
        `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_APPOINTMENT}/consultorio/list`,
        params,
        true
      );
      context.commit('POPULATE_LIST', response);
    },

    FETCH_PAY: async (context, params) => {
      const apiName = 'FinancialAPI';
      const path = '/payment';

      const myInit = {
        body:
          params.payment_type === 'PIX'
            ? {
                address: params.credit_card.address,
                credit_card: params.credit_card,
                payment_type: params.payment_type,
                people: params.people,
                phone_numbers: [params.credit_card.cell_phone],
                voucher: params?.credit_card?.voucher?.length > 0 ? params.credit_card.voucher : undefined,
              }
            : {
                ...params.credit_card,
                payment_type: params.payment_type,
              },
        headers: {},
        queryStringParameters: {
          access: params.id,
          protocol: params.protocol,
        },
        response: true,
      };

      myInit.headers['x-company-id'] = params.company_id;
      myInit.headers['x-utc-offset'] = moment().utcOffset().toString();

      const json = await API.post(apiName, path, myInit)
        .then((payload) => {
          if (payload.status === 200) {
            return payload.data;
          } else {
            return false;
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            context.commit('common/POPULATE_FEEDBACK', error.response.data, {
              root: true,
            });
            return error.response.data;
          } else {
            return {
              code: 400,
              message: 'Impossível acessar o servidor. Tente novamente',
            };
          }
        });

      context.commit('POPULATE_PAY', json);
    },

    FETCH_PAY_INLOCAL: async (context, params) => {
      const apiName = 'FinancialAPI';
      const path = '/localpayment';

      const myInit = {
        body: {},
        headers: {
          'x-utc-offset': moment().utcOffset().toString(),
        },
        query: {},
        queryStringParameters: {
          access: params.id,
        },
        response: true,
      };

      const json = await API.post(apiName, path, myInit)
        .then((payload) => {
          if (payload.status === 200) {
            return payload.data;
          } else {
            return false;
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            context.commit('common/POPULATE_FEEDBACK', error.response.data, {
              root: true,
            });
            return error.response.data;
          } else {
            return {
              code: 400,
              message: 'Impossível acessar o servidor. Tente novamente',
            };
          }
        });

      context.commit('POPULATE_PAY', json);
    },

    FETCH_REPORT: async (context, params) => {
      const response = await fetchHelper.fetchAll(
        `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_APPOINTMENT}/consultorio/report`,
        params,
        true
      );
      context.commit('POPULATE_REPORT', response);
    },

    FETCH_SAVE: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_APPOINTMENT}`, {
          body: JSON.stringify(params),
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
          },
          method: 'POST',
        });
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }

      if (json.data) {
        context.commit('POPULATE_ONE', json.data);
      }

      context.commit('common/POPULATE_FEEDBACK', json, {
        root: true,
      });
    },

    FETCH_SEND_FILE_LOGGED: async (context, params) => {
      let response;
      let json;
      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_APPOINTMENT}/${params.id}/file`, {
          body: JSON.stringify(params.file),
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
          },
          method: 'PUT',
        });
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }

      context.commit('POPULATE_FILE', json);
    },

    FETCH_SET_CANCEL: async (context, params) => {
      const apiName = 'AppointmentAPI';
      const path = '/consultorio/cancel';

      const myInit = {
        body: {},
        query: {},
        queryStringParameters: {
          access: params.id,
          reason: params.reason,
        },
        response: true,
      };

      const json = await API.put(apiName, path, myInit)
        .then((payload) => {
          if (payload.status === 200) {
            return payload.data;
          } else {
            return false;
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            context.commit('common/POPULATE_FEEDBACK', error.response.data, {
              root: true,
            });
            return error.response.data;
          } else {
            return {
              code: 400,
              message: 'Impossível acessar o servidor. Tente novamente',
            };
          }
        });

      context.commit('common/POPULATE_FEEDBACK', json, {
        root: true,
      });
    },

    FETCH_UPDATE: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_APPOINTMENT}/${params.id}`, {
          body: JSON.stringify(params),
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
          },
          method: 'PUT',
        });
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }

      context.commit('common/POPULATE_FEEDBACK', json, {
        root: true,
      });
    },

    FETCH_UPDATE_REASON: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_APPOINTMENT}/${params.id}/reason`, {
          body: JSON.stringify(params),
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
          },
          method: 'PUT',
        });
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }

      context.commit('common/POPULATE_FEEDBACK', json);
    },
  },
  getters: {
    GET_ACCESSROOM: (state) => state.accessroom,
    GET_ALL: (state) => state.entities,
    GET_CANCEL: (state) => state.cancel,
    GET_CONFIRM_PAYMENT: (state) => {
      return JSON.parse(JSON.stringify(state.confirm_payment));
    },
    GET_FILE: (state) => {
      return JSON.parse(JSON.stringify(state.file));
    },
    GET_LIST: (state) => state.list,
    GET_ONE: (state) => {
      return JSON.parse(JSON.stringify(state.entity));
    },
    GET_PAY: (state) => state.pay,
    GET_PAYMENT: (state) => JSON.parse(JSON.stringify(state.payment)),
    GET_REPORT: (state) => state.report,
    GET_VOUCHERVALID: (state) => JSON.parse(JSON.stringify(state.vouchervalid)),
    NEXT_ATTENDANCES: (state) => state.next_attendances,
    TERM_USER: (state) => state.term_user,
  },
  mutations: {
    POPULATE_ALL: (state, payload) => {
      if (payload) {
        state.entities = payload;
      }
    },
    POPULATE_CANCEL: (state, payload) => {
      if (payload) {
        state.cancel = payload;
      }
    },
    POPULATE_CONFIRM_PAYMENT: (state, payload) => {
      if (payload) {
        state.confirm_payment = payload;
      }
    },
    POPULATE_FILE: (state, payload) => {
      if (payload) {
        state.file = payload;
      }
    },
    POPULATE_LIST: (state, payload) => {
      if (payload) {
        state.list = payload;
      }
    },
    POPULATE_NEXT_ATTENDANCES: (state, payload) => {
      if (payload) {
        state.next_attendances = payload;
      }
    },
    POPULATE_ONE: (state, payload) => {
      if (payload) {
        state.entity = payload;
      }
    },
    POPULATE_PAY: (state, payload) => {
      if (payload) {
        state.pay = payload;
      }
    },
    POPULATE_PAYMENT: (state, payload) => {
      if (payload) {
        state.payment = payload;
      }
    },
    POPULATE_REPORT: (state, payload) => {
      if (payload) {
        state.report = payload;
      }
    },
    POPULATE_VOUCHERVALID: (state, payload) => {
      if (payload) {
        state.vouchervalid = payload;
      }
    },
  },
  namespaced: true,
  state: {
    accessroom: null,
    cancel: null,
    confirm_payment: null,
    edit: null,
    entities: [],
    entity: null,
    file: null,
    list: null,
    new: [],
    next_attendances: null,
    pay: null,
    payment: null,
    report: null,
    saveNote: null,
    term_user: [],
    vouchervalid: null,
  },
};

CommonState.registerModule('appointment', module);
